import * as React from 'react';
import { graphql } from 'gatsby';

import { filterSeries } from 'services';
import { classesDataSelector } from 'selectors/classes';
import { useSelector } from 'services/hooks';
import { Template } from  'common/layout';
import {
  ClassesBlock, CheckoutModal, CheckoutProvider, PricingOverview, PricingHeader,
} from 'modules/PricingPage';
import { PromoBlock } from 'modules/contentBlocks';

const PricingPage = () => {
  const classes = useSelector(classesDataSelector);

  return (
    <Template>
      <CheckoutProvider>
        <PricingHeader />
        <PromoBlock />
        <PricingOverview />
        <ClassesBlock classes={filterSeries(classes)} />
        <CheckoutModal />
      </CheckoutProvider>
    </Template>
  );
};

export const query = graphql`
  query PricingPage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;

export default PricingPage;
